
import { defineComponent, onMounted, computed } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "notification-component",
  components: {},
  data() {
    return {
      notifications: [{} as any],
    };
  },
  setup() {
    const user = computed(() => {
      return store.getters.currentUser;
    });

    onMounted(() => {
      setCurrentPageTitle("Notifications");
    });

    return { user };
  },
  created() {
    // Get notifications
    this.getNotifications();
    this.clearNotifications();

    //Set page title
    document.title = "Notifications | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getNotifications() {
      // Fetch all notifications
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.NOTIFICATIONS_ROUTE)
          .then(({ data }) => {
            //Assign data to variables
            this.notifications = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    clearNotifications() {
      // Clear notifications on page selection
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.put(`${variables.NOTIFICATIONS_ROUTE}/last-seen`, {})
          .then(({ data }) => {
            console.log(data);
            return;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    markAsRead(publicId) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.post(`${variables.NOTIFICATIONS_ROUTE}/${publicId}`, {})
          .then(({ data }) => {
            store.commit(
              Mutations.NOTIFICATION_MUTATION,
              data.data.notifications
            );
            this.getNotifications();
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
  },
});
